import { combineReducers } from 'redux';
import oddsReducer from './oddsReducer'; // Create this reducer
import cardsReducer from './cardsReducer';

const rootReducer = combineReducers({
    odds: oddsReducer,
    cards: cardsReducer
    // Add more reducers as needed
});

export default rootReducer;
