import styles from './css/Timer.module.css';
import { useState } from 'react';
// import { useEffect } from 'react';

function Timer(props) {
    const {timeLimit} = props
    const FULL_DASH_ARRAY = 283;

    const [timeLeft, /* setTimeLeft */] = useState(timeLimit);
    const [circle, /* setCircle */] = useState(FULL_DASH_ARRAY);

    function formatTime(time) {
        const seconds = time % 60;
        const secondsText = seconds < 10 ? `0${seconds}` : seconds;
        const minutes = Math.floor(time / 60);
        const minutesText = minutes < 10 ? `0${minutes}` : minutes;
        return `${minutesText}:${secondsText}`
    }
    /*
    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
                const rawTimeFraction = timeLeft / timeLimit;
                const timeFraction = rawTimeFraction - (1 / timeLimit) * (1 - rawTimeFraction);
                const circleDasharray = `${(timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
                setCircle(circleDasharray);
            }
        }, 1000);
    });
    */

    return (
        <div className={styles.container}>
            <div className={styles.baseTimer}>
                <svg className={styles.svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <g className={styles.circle}>
                        <circle className={styles.pathElapsed} cx="50" cy="50" r="45" />
                        <path
                            id="base-timer-path-remaining"
                            stroke-dasharray={circle}
                            className={styles.pathRemaining}
                            d="
                                M 50, 50
                                m -45, 0
                                a 45,45 0 1,0 90,0
                                a 45,45 0 1,0 -90,0
                            "
                        ></path>
                        <defs>
                            <linearGradient id="MyGradient" x1="0" x2="0" y1="1" y2="0">
                                <stop offset="-50%" stop-color="#05FFC5" />
                                <stop offset="150%" stop-color="#B951F2" />
                            </linearGradient>
                        </defs>
                    </g>
                </svg>
                <div className={styles.timerText}>
                    <p className={styles.countdownText}>{formatTime(timeLeft)}</p>
                    <p className={styles.timeRemaining}>Time remaining</p>
                </div>
            </div>
        </div>
    )
}

export default Timer;
