import styles from './css/ProfileBanner.module.css';
import bannerImage from "../../assets/banner.png";
import { useState, useEffect } from 'react';

function ProfileBanner(props) {
    const { setTab, username, location, dateJoined } = props;
    const [tab, setCurrentTab] = useState(0);

    useEffect(() => setTab(tab), [tab, setTab]);

    const activeStyle = `${styles.tabButton} ${styles.activeTab}`;
    const tabTitles = ["PROFILE", "PREDICTIONS", "PRIZES"];
    const tabs = [];

    for (const [idx, name] of tabTitles.entries()) {
        var style = tab === idx ? activeStyle : styles.tabButton;
        tabs.push(<div className={style} onClick={() => setCurrentTab(idx)}>{name}</div>)
    }

    return (
        <div className={styles.container}>
            <img src={bannerImage} className={styles.bannerImage}/>
            <div className={styles.userInfoContainer}>
                <div className={styles.profilePictureContainer}>
                    <div className={styles.profilePicture}>
                        <div className={styles.profilePictureOverlay}>
                            <p>Change Picture</p>
                        </div>
                    </div>
                </div>

                <div className={styles.userInfo}>
                    <p className={styles.username}>@{username}</p>
                    <p className={styles.userDetails}>{location}</p>
                    <p className={styles.userDetails}>Member since: {dateJoined}</p>
                </div>
            </div>

            <div className={styles.tabsContainer}>
                { tabs }
            </div>
        </div>
    )
}

export default ProfileBanner
