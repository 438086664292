import React from 'react';
import styles from './css/OddsCard.module.css';

function OddsCard(props) {
    const { oddsInfo, selectedOdds, updateSelectedOdds } = props;
    const { helper_text, selections } = oddsInfo;

    const handleClick = (id) => {
        updateSelectedOdds(id);
    };

    return (
        <div className={styles.card}>
            <div className={styles.marketType}>
                {helper_text}
            </div>
            <div className={styles.oddsDetails}>
                {selections.map((selection) => (
                    selection.decimal_odd && (
                        <div
                            key={selection.id}
                            className={`${styles.oddsRow} ${selectedOdds.includes(selection.id) ? styles.selected : ''}`}
                            onClick={() => handleClick(selection.id)}
                        >
                            <span className={styles.teamName}>{selection.option}</span>
                            <span className={styles.oddsValue}>{selection.decimal_odd.toFixed(2)}</span>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

export default OddsCard;
