import styles from "./css/BottomQuestion.module.css";

function BottomQuestion(props) {
  const {
    // gameId,
    question,
    duration,
    // matchid,
    // userid,
    questionId,
    questionAnswered,
  } = props;

  //alert(question);

  function answer_functions(answerid) {
    questionAnswered(answerid, questionId);
  }
  //alert("bot quest inside return");
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.paginationText}>{questionId + 1}</div>
        <p className={styles.questionText}>{question.question_text}</p>
        <div className={styles.optionText} onClick={() => answer_functions(0)}>
          {question.answers[0].answer_text + ` [${question.odds[0]}x]`}
        </div>
        <div className={styles.optionText} onClick={() => answer_functions(1)}>
          {question.answers[1].answer_text + ` [${question.odds[1]}x]`}
        </div>
      </div>

      <div className={styles.timer}>
        <div
          className={styles.shrinkingContainer}
          style={{ "--duration": duration }}
        >
          <div className={styles.shrinkingBackground}></div>
        </div>
      </div>
    </div>
  );
}

export default BottomQuestion;
