import styles from './css/TimerPanel.module.css';
import Timer from './Timer';

function TimerPanel(props) {
    const {currentRound, totalRounds, timeLimit} = props;

    return (
        <div className={styles.container}>
            <div className={styles.rounds}>Round {currentRound} of {totalRounds} </div>
            <Timer timeLimit={timeLimit}/>
        </div>
    )
}

export default TimerPanel
