import styles from "./css/HomeBody.module.css";
import homeFortnite from '../../assets/homeFortnite.png';
import { Link } from 'react-router-dom';

function HomeBody() {
    return (
        <div className={styles.bodyContainer}>
            <div className={styles.headingContainer}>
                <div className={styles.titleContainer}>
                    <p className={styles.title}>GET YOUR PRIZES</p>
                    <hr className={styles.lineOne}/>
                    <hr className={styles.lineTwo}/>
                </div>
                <p className={styles.subtitle}>Showcase your knowledge of the game and win prizes</p>
            </div>
            
            <div className={styles.prizeContainer}>
                <div className={styles.prize}>Skins</div>
                <div className={styles.prize}>Gift cards</div>
                <div className={styles.prize}>Meet&Greets</div>
                <div className={styles.prize}>Games</div>
            </div>

            <div className={styles.bottomContainer}>
                <div className={styles.bottomLeftContainer}>
                    <p className={styles.description}>Rimble is where esport fans gather together every day to chat, interact, predict, and make their own adventures. Watch, game, and predict with us.</p>
                <Link to="/play" className={styles.streamBtn}>STREAMS</Link>
                </div>
                <img src={homeFortnite} className={styles.fortniteImg}/>
            </div>
        </div>
    );
}

export default HomeBody;