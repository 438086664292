// src/store/reducers/oddsReducer.js

const initialState = {
    selectedOdds: [],
};

const oddsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SELECTED_ODDS':
            return {
                ...state,
                selectedOdds: action.payload,
            };
        default:
            return state;
    }
};

export default oddsReducer;
