import styles from './css/PlayNowHeader.module.css';
import React, { useState } from 'react';

function PlayNowHeader({ onFilterChange }) {
    const [activeFilter, setActiveFilter] = useState('Home');

    const handleFilterClick = (filter) => {
        setActiveFilter(filter);
        onFilterChange(filter);
    };

    return (
        <div className={styles.headerContainer}>
            <div className={styles.buttonContainer}>
                <div
                    className={`${styles.filter} ${activeFilter === 'Home' ? styles.activeFilter : ''}`}
                    onClick={() => handleFilterClick('Home')}
                >
                    Home
                </div>
                <div
                    className={`${styles.filter} ${activeFilter === 'In-Play' ? styles.activeFilter : ''}`}
                    onClick={() => handleFilterClick('In-Play')}
                >
                    In-Play
                </div>
                <div
                    className={`${styles.filter} ${activeFilter === 'Upcoming' ? styles.activeFilter : ''}`}
                    onClick={() => handleFilterClick('Upcoming')}
                >
                    Upcoming
                </div>
            </div>
        </div>
    );
}

export default PlayNowHeader;
