import styles from "./css/Odds.module.css";
import OddsRow from "./OddsRow.js";
// import graph from "../../assets/graph.png";
import { useState } from "react";
// import { useMemo, useRef, useEffect } from "react";

function OddsPanel(props) {
  const {
    flashOdds,
    persistentOdds,
    answeredQuestions,
    questionAnswered,
    answers,
  } = props;
  const [betSelected, setBetSelected] = useState(null);
  const [optionSelected, setOptionSelected] = useState(null);
  const [betOdd, setBetOdd] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [bets, setBets] = useState({});

  function reverseArray(arr) {
    var newArray = [];
    for (var i = arr.length - 1; i >= 0; i--) {
      newArray.push(arr[i]);
    }
    return newArray;
  }

  function answer_functions() {
    if (betAmount > 0) {
      const newBets = {
        ...bets,
        [betSelected]: {
          optionSelected,
          betAmount,
          betOdd,
        },
      };
      setBets(newBets);
      handleCancel();
      questionAnswered(optionSelected, betSelected);
    }
  }

  const handleSelect = (question, answerid) => {
    setOptionSelected(answerid);
    setBetSelected(question.identifier.id);
    setBetOdd(question.outcomes[answerid].decimal_odd);
  };

  const handleSelectBetAmount = (amount) => {
    if (betSelected != null) {
      setBetAmount(amount);
    }
  };

  const handleCancel = () => {
    setOptionSelected(null);
    setBetSelected(null);
    setBetOdd(null);
    setBetAmount(0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleText}>Flash Market</div>
      <div className={styles.oddsContainer}>
        {flashOdds.length > 0
          ? reverseArray(flashOdds)
              .slice(0, 1)
              .map((question) => (
                <OddsRow
                  key={question.identifier.id}
                  duration={60}
                  question={question}
                  handleSelect={handleSelect}
                  betSelected={betSelected}
                  optionSelected={optionSelected}
                  persistent={false}
                  answer={false}
                  answeredQuestion={
                    question.identifier.id in answeredQuestions
                      ? answeredQuestions[question.identifier.id]
                      : null
                  }
                />
              ))
          : null}
        {answers.length > 0
          ? reverseArray(answers)
              .slice(0, 1)
              .map((answer) => (
                <OddsRow
                  key={answer.identifier.id}
                  duration={0}
                  question={answer}
                  persistent={false}
                  answer={true}
                  bet={
                    answer.identifier.id in bets
                      ? bets[answer.identifier.id]
                      : null
                  }
                  answeredQuestion={
                    answer.identifier.id in answeredQuestions
                      ? answeredQuestions[answer.identifier.id]
                      : null
                  }
                />
              ))
          : null}
      </div>
      <div
        className={styles.titleText}
        style={{ marginTop: Object.keys(flashOdds).length > 1 ? "0" : "30%" }}
      >
        Persistent Market
      </div>
      <div className={styles.oddsContainer}>
        {Object.keys(persistentOdds).length > 0
          ? reverseArray(Object.keys(persistentOdds))
              .slice(0, 1)
              .map((key, index) => (
                <OddsRow
                  key={key}
                  duration={0}
                  question={persistentOdds[key]}
                  handleSelect={handleSelect}
                  betSelected={betSelected}
                  optionSelected={optionSelected}
                  persistent={true}
                  answer={false}
                  answeredQuestion={
                    key in answeredQuestions ? answeredQuestions[key] : null
                  }
                  // userid={userid}
                  // questionId={newQuestionQuestionId}
                />
              ))
          : null}
      </div>
      <div className={styles.graphContainer}>
        <div className={styles.optionContainer}>
          <div
            className={styles.betOptionText}
            onClick={() => handleSelectBetAmount(5)}
          >
            $5
          </div>
          <div
            className={styles.betOptionText}
            onClick={() => handleSelectBetAmount(25)}
          >
            $25
          </div>
          <div
            className={styles.betOptionText}
            onClick={() => handleSelectBetAmount(100)}
          >
            $100
          </div>
        </div>
        <div className={styles.betContainer}>
          <div className={styles.amountText}>Total Stake</div>
          <div className={styles.amountText}>${betAmount}</div>
        </div>
        <div className={styles.betContainer}>
          <div className={styles.amountText}>To Win</div>
          <div className={styles.amountText}>
            ${(betAmount * betOdd).toFixed(2)}
          </div>
        </div>
        <div className={styles.placeBetContainer}>
          <div className={styles.cancelBetText} onClick={handleCancel}>
            Cancel
          </div>
          <div
            className={styles.placeBetText}
            style={{ background: betAmount > 0 ? "#532f84" : "#2f2838" }}
            onClick={() => answer_functions()}
          >
            Place Bet
          </div>
        </div>
      </div>
    </div>
  );
}

export default OddsPanel;
