import styles from './css/QuestionPanel.module.css';
import {useState} from 'react';
import QuestionCard from './QuestionCard';
import {BsCaretLeftFill, BsCaretRightFill} from 'react-icons/bs'

function QuestionPanel(props) {
    const {questions} = props;

    const [questionIndex, setQuestionIndex] = useState(0);

    function goToNextQuestion() {
        let length = questions.length;
        questionIndex === length - 1 ? setQuestionIndex(0) : setQuestionIndex(questionIndex + 1);
    }

    function goToPrevQuestion() {
        let length = questions.length;
        questionIndex < 1 ? setQuestionIndex(length - 1) : setQuestionIndex(questionIndex - 1);
    }

    const questionCards = []
    for (let question in questions) {
        questionCards.push(<QuestionCard questionInfo={questions[question]} />)
    }

    return (
        <div className={styles.container}>
            <BsCaretLeftFill className={styles.icon} onClick={goToPrevQuestion}/>
            {questionCards[questionIndex]}
            <BsCaretRightFill className={styles.icon} onClick={goToNextQuestion}/>
        </div>
    )
}

export default QuestionPanel
