import styles from './css/ProfilePredictions.module.css';
import PredictionCard from './PredictionCard';

function ProfilePredictions(props) {
    const predictionHistory = [
        {"timePredicted": "18hr ago", "tournament" : "CBLOL ACADEMY", "player1" : {"name": "PLAYER A", "image": ""}, "player2" : {"name": "PLAYER B", "image": ""}},
        {"timePredicted": "20hr ago", "tournament" : "CBLOL ACADEMY", "player1" : {"name": "PLAYER A", "image": ""}, "player2" : {"name": "PLAYER B", "image": ""}}
    ]
    
    const predictionsList = []
    for (let prediction in predictionHistory) {
        predictionsList.push(<PredictionCard prediction={predictionHistory[prediction]} />)
    }

    return (
        <div className={styles.container}>
            <p className={styles.title}>PREDICTIONS AND STATISTICS</p>
            <p className={styles.subtitle}>Change identifying information and details about your account.</p>
            <div className={styles.content}>
                <div className={styles.left}></div>
                <div className={styles.right}>
                    {predictionsList}
                </div>
            </div>
        </div>
    )
}

export default ProfilePredictions
