import React from 'react';
import styles from './css/OddsSidebarCard.module.css';

function OddsSidebarCard({ helperText, option, decimalOdd }) {
    return (
        <div className={styles.card}>
            <div className={styles.helperText}>{helperText}</div>
            <div className={styles.cardFooter}>
                <span>{option}</span>
                <span>{decimalOdd.toFixed(2)}</span>
            </div>
        </div>
    );
}

export default OddsSidebarCard;
