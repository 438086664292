import styles from "./css/RedeemPanel.module.css";
import congratulationsImage from '../../assets/congratulations.png';

function RedeemPanel() {
    function copyLink() {
        var dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.value = document.getElementById("redeemLink").innerHTML;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }

    return (
        <div className={styles.container}>
            <div className={styles.placeholder}>
                <img src={congratulationsImage} className={styles.congratulationsImage}/>
            </div>
            <div className={styles.redeemLinkContainer}>
                <p className={styles.redeem}>REDEEM PRIZES</p>
                <p className={styles.redeemLink} id="redeemLink">www.linktoredeemlinktoredeem.org</p>
                <div className={styles.redeemBtn} onClick={copyLink}>COPY</div>
            </div>
        </div>
    )
}

export default RedeemPanel
