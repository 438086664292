import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedOdds } from '../../store/actions/oddsActions';

import styles from './css/OddsPanel.module.css';
import OddsCard from './OddsCard';

function OddsPanel(props) {
    const { odds, matchRounds } = props;
    const dispatch = useDispatch(); // Redux dispatch function

    const [selectedTabs, setSelectedTabs] = useState(() => {
        const savedTabs = localStorage.getItem('selectedTabs');
        return savedTabs ? JSON.parse(savedTabs) : ['All']; // Default 'All' selected if no saved state
    });
    const [selectedOdds, setSelectedOdds] = useState(() => {
        const savedOdds = localStorage.getItem('selectedOdds');
        return savedOdds ? JSON.parse(savedOdds) : []; // Default empty array if no saved state
    });

    // Save state to localStorage whenever selectedOdds changes
    useEffect(() => {
        localStorage.setItem('selectedOdds', JSON.stringify(selectedOdds));
        dispatch(updateSelectedOdds(selectedOdds)); // Ensure Redux state is updated
    }, [selectedOdds, dispatch]);

    // Load saved state from localStorage on mount
    useEffect(() => {
        const savedTabs = localStorage.getItem('selectedTabs');
        if (savedTabs) setSelectedTabs(JSON.parse(savedTabs));

        const savedOdds = localStorage.getItem('selectedOdds');
        if (savedOdds) setSelectedOdds(JSON.parse(savedOdds));
    }, []);

    const handleUpdateSelectedOdds = (selectedId) => {
        const marketId = selectedId.split('_')[0] + '_' + selectedId.split('_')[1];

        if (selectedOdds.includes(selectedId)) {
            const updatedOdds = selectedOdds.filter(item => item !== selectedId);
            setSelectedOdds(updatedOdds);
        } else {
            const updatedSelectedOdds = selectedOdds.filter(item => !item.startsWith(marketId));
            const updatedOdds = [...updatedSelectedOdds, selectedId];
            setSelectedOdds(updatedOdds);
        }
    };

    const handleTabClick = (tab) => {
        if (selectedTabs.length === 1 && selectedTabs.includes(tab)) {
            setSelectedTabs(['All']);
            return;
        }
        if (selectedTabs.length === 1 && selectedTabs.includes('All') && !selectedTabs.includes(tab)) {
            setSelectedTabs([tab]);
            return;
        }
        if (tab === 'All') {
            setSelectedTabs(['All']);
            return;
        }
        if (!selectedTabs.includes(tab)) {
            setSelectedTabs([...selectedTabs, tab]);
        } else {
            setSelectedTabs(selectedTabs.filter(item => item !== tab));
        }
    };

    // Filter odds based on the selected tabs
    const filteredOdds = odds.filter(oddsInfo => {
        return selectedTabs.every(tab => {
            switch (tab) {
                case 'All':
                    return true;
                case 'Overall':
                    return oddsInfo.identifier.map_specific_stat === null;
                case 'Kills':
                    return oddsInfo.identifier.stat === 'kills';
                case 'Deaths':
                    return oddsInfo.identifier.stat === 'deaths';
                case 'Assists':
                    return oddsInfo.identifier.stat === 'assists';
                case 'Winner':
                    return oddsInfo.identifier.market_type === 'map_winner' || oddsInfo.identifier.market_type === 'match_winner';
                case 'Rounds':
                    return oddsInfo.identifier.stat === 'rounds';
                default:
                    if (tab.startsWith('Map ')) {
                        const mapNumber = parseInt(tab.split(' ')[1]);
                        return oddsInfo.identifier.map_specific_stat === mapNumber;
                    }
                    return false;
            }
        });
    });

    // Dynamically generate map tabs based on matchRounds
    const mapTabs = [];
    for (let i = 1; i <= matchRounds; i++) {
        mapTabs.push(
            <button
                key={`Map ${i}`}
                className={selectedTabs.includes(`Map ${i}`) ? styles.activeTab : ''}
                onClick={() => handleTabClick(`Map ${i}`)}
            >
                {`Map ${i}`}
            </button>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <button
                    className={selectedTabs.includes('All') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('All')}
                >
                    All
                </button>
                {mapTabs}
                <button
                    className={selectedTabs.includes('Overall') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('Overall')}
                >
                    Overall
                </button>
                <button
                    className={selectedTabs.includes('Kills') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('Kills')}
                >
                    Kills
                </button>
                <button
                    className={selectedTabs.includes('Deaths') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('Deaths')}
                >
                    Deaths
                </button>
                <button
                    className={selectedTabs.includes('Assists') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('Assists')}
                >
                    Assists
                </button>
                <button
                    className={selectedTabs.includes('Winner') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('Winner')}
                >
                    Winner
                </button>
                <button
                    className={selectedTabs.includes('Rounds') ? styles.activeTab : ''}
                    onClick={() => handleTabClick('Rounds')}
                >
                    Rounds
                </button>
                {/* Add more tabs here as needed */}
            </div>
            <div className={styles.oddsList}>
                {filteredOdds.length > 0 ? filteredOdds.map((oddsInfo, index) => (
                    <OddsCard
                        key={index}
                        oddsInfo={oddsInfo}
                        selectedOdds={selectedOdds}
                        updateSelectedOdds={handleUpdateSelectedOdds}
                    />
                )) : <p>No odds available for the selected filters.</p>}
            </div>
        </div>
    );
}

export default OddsPanel;
