import { useState } from "react";
import styles from "./css/GameSidebar.module.css";
import { BsChatSquareDots, BsArrowBarRight } from "react-icons/bs";
import TwitchChat from "./TwitchChat";
import OddsPanel from "../Odds/OddsPanel.js";

function OddsGameSidebar(props) {
  const {
    flashOdds,
    persistentOdds,
    answeredQuestions,
    questionAnswered,
    answers,
  } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [isBet, setIsBet] = useState(false);

  function toggleBets(item) {
    let bet = document.getElementsByClassName(styles.statsBtn)[0];
    let chat = document.getElementsByClassName(styles.chatBtn)[0];
    let goBet = item === "bet";
    setIsBet(goBet);
    bet.style.borderColor = goBet ? "#00AC82" : "transparent";
    chat.style.borderColor = !goBet ? "#00AC82" : "transparent";
  }

  const sidebar = (
    <div className={styles.container}>
      <div className={styles.menu}>
        <BsArrowBarRight
          className={styles.closeChatIcon}
          onClick={setIsOpen.bind(this, false)}
        />
        <p className={styles.chatBtn} onClick={toggleBets.bind(this, "chat")}>
          CHAT
        </p>
        <p className={styles.statsBtn} onClick={toggleBets.bind(this, "bet")}>
          BET
        </p>
      </div>

      <div className={styles.content}>
        <TwitchChat channel={"itsRyanHiga"} width="100%" height="100%" />
        {isBet && (
          <div className={styles.oddsContainer}>
            <OddsPanel
              flashOdds={flashOdds}
              persistentOdds={persistentOdds}
              answeredQuestions={answeredQuestions}
              questionAnswered={questionAnswered}
              answers={answers}
            />
          </div>
        )}
      </div>
    </div>
  );

  const openChat = (
    <div
      className={styles.openChatIcon}
      onClick={setIsOpen.bind(this, !isOpen)}
    >
      <BsChatSquareDots />
    </div>
  );

  return isOpen ? sidebar : openChat;
}

export default OddsGameSidebar;
