import styles from "./css/Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
    const sections = { 
        "About": {
            "Story": "/story",
            "Who We Are": "/who",
            "Services": "/services",
            "Commitment": '/commitment'
        },
        "CONTACT": {}
    }

    const footerSections = []
    for (let section in sections) {
        const content = [<p key={section} className={styles.contentTitle}>{section}</p>]
        for (let subsection in sections[section]) {
            content.push(<Link key={subsection} to={sections[section][subsection]} className={styles.greyText}>{subsection}</Link>)
        }
        footerSections.push(<div key={section} className={styles.linksContainer}>{content}</div>)
    }

    return (
        <div className={styles.footerContainer}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>Reinventing the esports experience. Let’s play together. </p>
                <div className={styles.contactBtn}>CONTACT</div>
            </div>
            
            <hr className={styles.divider}/>

            <div className={styles.mainContent}>
                <div>
                    <p className={styles.contentTitle}>Rimble</p>
                    <a className={styles.email} href="mailto:support@rimble.io">support@rimble.io</a>
                    <p className={styles.greyText}>San Francisco, CA</p>
                </div>
                <div className={styles.contentRight}>
                    {footerSections}
                </div>
            </div>
            
            <div className={styles.licensingContainer}>
                <p className={styles.company}>2020 Rimble, All Rights Reserved</p>
                <p className={styles.terms}>Terms & Conditions</p>
            </div>
        </div>
    )
}

export default Footer
