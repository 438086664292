import styles from './css/HowToPlay.module.css';
import HowSteps from '../components/HowToPlay/HowSteps';
import Footer from '../components/Global/Footer';
import circle from "../assets/Circle.svg";
import TextOverlay from "../components/Global/TextOverlay";

function HowToPlayPage() {
    return (
        <div className={styles.homeContainer}>
            <div className={styles.bodyContainer}>
                <div className={styles.topContainer}>
                    <div className={styles.lineContainer}>
                        <hr className={styles.lineOne}/>
                        <hr className={styles.lineFour}/>
                    </div>
                    <div className={styles.titleTextContainer}>
                        <TextOverlay textClass={styles.titleText}>A FULL BEGINNER GUIDE TO WINNING GAMES</TextOverlay>
                        <p className={styles.titleSubheader}>How do I utilize Rimble and win prizes? Feel free to chat us if <br/>you have any questions!</p>
                    </div>
                    <div className={styles.lineContainer}>
                        <hr className={styles.lineTwo}/>
                        <hr className={styles.lineFive}/>
                    </div>
                    <div className={styles.lineContainer}>
                        <hr className={styles.lineThree}/>
                        <hr className={styles.lineSix}/>
                    </div>
                </div>
                <div className={styles.stepsContainer}>
                    <HowSteps number="1" title="SIGN UP AND PLAY NOW" actionText="register page"/>
                    <HowSteps number="2" title="PREGAME" actionText="pregame now"/>
                    <HowSteps number="3" title="LIVE WATCH & PREDICT" actionText="register page"/>
                    <HowSteps number="4" title="COLLECT PRIZES" actionText="register page"/>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.bottomContainerContent}>
                        <p className={styles.walkthroughTitleText}>WALKTHROUGH VIDEO</p>
                        <hr className={styles.lineSeven}/>
                        <div className={styles.videoContainer}>
                            <img src="http://placehold.it/640x480" className={styles.video}></img>
                            <div className={styles.descriptionContainer}>
                                <p className={styles.description}>Visually learn how to <br/>use Rimble.</p>
                                <p className={styles.description}>Questions?<br/>We have answers.<br/>Message us.</p>
                                <div className={styles.redirectTutorial}>
                                    <p className={styles.tutorial}>tutorials</p>
                                    <img src={circle} className={styles.icon}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default HowToPlayPage;