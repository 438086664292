// src/store/reducers/cardsReducer.js

const initialState = {
    selectedCards: [],
};

const oddsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_SELECTED_CARDS':
            return {
                ...state,
                selectedCards: action.payload,
            };
        default:
            return state;
    }
};

export default oddsReducer;
