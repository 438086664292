import styles from "./css/PredictionCard.module.css"

function PredictionCard(props) {
    const {prediction} = props;

    return (
        <div className={styles.container}>
            <p className={styles.time}>{prediction["timePredicted"]}</p>
            <p className={styles.title}>{prediction["tournament"]}</p>
            <div className={styles.playerContent}>
                <div className={styles.playerContainer}>
                    <div className={styles.playerImage}></div>
                    <p className={styles.playerName}>{prediction["player1"]["name"]}</p>
                </div>
                <p className={styles.vs}>VS</p>
                <div className={styles.playerContainer}>
                    <div className={styles.playerImage}></div>
                    <p className={styles.playerName}>{prediction["player2"]["name"]}</p>
                </div>
            </div>
        </div>
    )
}

export default PredictionCard
