import styles from './css/QuestionCard.module.css';
// import { useState } from 'react'

function QuestionCard(props) {
    const {questionInfo} = props;

    const question = questionInfo["question_text"];
    const id = questionInfo["ID"];
    const answers = questionInfo["answers"]

    const answerChoices = []
    for (let index in answers) {
        answerChoices.push(<div className={styles.answerChoice} id={answers[index]["answerID"]}>{answers[index]["answer_text"]}</div>)
    }

    return (
        <div className={styles.container} key={id}>
            <p className={styles.question}>{question}</p>
            <div className={styles.userInputContainer}>
                <div className={styles.answerContainer}>
                    {answerChoices}
                </div>
                <div className={styles.inputContainer}>
                    <input type="text" placeholder="Want to say something? Type here." className={styles.input}></input>
                </div>
            </div>
        </div>
    )
}

export default QuestionCard
