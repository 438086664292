import styles from "./css/PregameQuestionsPage.module.css";
import TimerPanel from "../components/Pregame/TimerPanel";
import QuestionPanel from "../components/Pregame/QuestionPanel";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
// import { useHistory } from 'react-router-dom';

function PregameQuestionsPage() {
    const { gameid } = useParams();
    // let history = useHistory();

    const [questionsList, setQuestionsList] = useState([]);
    const [totalRounds, setTotalRounds] = useState(0);
    const [answers, setAnswers] = useState({});

    function updateAnswers(id, answer, answerID) {
        setAnswers(prevState => ({
            ...prevState,
            [id]: answerID
        }));
        console.log("Answers:", JSON.stringify(answers));
    }

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const lolResponse = await fetch(`https://rimbleanalytics.com/engagement/lol/questions/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                    redirect: 'follow'
                });
                const lolJson = await lolResponse.json();
                // console.log("LOL Response:", lolJson); // Log the LOL response
                if (lolJson && lolJson[0].pregame_questions) {
                    setQuestionsList(lolJson[0].pregame_questions);
                    setTotalRounds(lolJson[0].pregame_questions.length);
                    return;
                }
            } catch (error) {
                console.error("Error fetching LOL questions:", error);
            }

            try {
                const valorantResponse = await fetch(`https://rimbleanalytics.com/engagement/valorant/questions/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                    redirect: 'follow'
                });
                const valorantJson = await valorantResponse.json();
                // console.log("Valorant Response:", valorantJson); // Log the Valorant response
                if (valorantJson && valorantJson[0].pregame_questions) {
                    setQuestionsList(valorantJson[0].pregame_questions);
                    setTotalRounds(valorantJson[0].pregame_questions.length);
                    return;
                }
            } catch (error) {
                console.error("Error fetching Valorant questions:", error);
            }

            try {
                const csgoResponse = await fetch(`https://rimbleanalytics.com/engagement/csgo/questions/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                    redirect: 'follow'
                });
                const csgoJson = await csgoResponse.json();
                // console.log("CSGO Response:", csgoJson); // Log the CSGO response
                if (csgoJson && csgoJson[0].pregame_questions) {
                    setQuestionsList(csgoJson[0].pregame_questions);
                    setTotalRounds(csgoJson[0].pregame_questions.length);
                    return;
                }
            } catch (error) {
                console.error("Error fetching CSGO questions:", error);
            }
        };

        fetchQuestions();
    }, [gameid]);

    useEffect(() => {
        console.log("Questions List Updated:", questionsList); // Log questionsList whenever it updates
    }, [questionsList]);

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.titleContainer}>
                        <p className={styles.title}>WE ARE PREGAMING</p>
                        <p className={styles.titleShadow}>WE ARE PREGAMING</p>
                    </div>
                    <TimerPanel currentRound={Object.keys(answers).length + 1} totalRounds={totalRounds} timeLimit={20} />
                    <QuestionPanel questions={questionsList} onClick={updateAnswers} />
                </div>
            </div>
        </div>
    );
}

export default PregameQuestionsPage;
