import React, { useState, useEffect } from 'react';
import styles from './css/OddsSidebarBetPanel.module.css';

function OddsSidebarBetPanel({ combinedOdds }) {
    const [betSize, setBetSize] = useState(0.50);
    const [totalPayout, setTotalPayout] = useState(0);

    useEffect(() => {
        setTotalPayout((betSize * combinedOdds).toFixed(2));
    }, [betSize, combinedOdds]);

    const handleBetSizeChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setBetSize(value);
        }
    };

    return (
        <div className={styles.betPanel}>
            <div className={styles.betSizeContainer}>
                <label>Bet Size:</label>
                <input
                    type="text"
                    value={betSize}
                    onChange={handleBetSizeChange}
                />
            </div>
            <div className={styles.totalPayoutContainer}>
                <label>Total Payout:</label>
                <p>{totalPayout}</p>
            </div>
        </div>
    );
}

export default OddsSidebarBetPanel;
