import './App.css';
import { useEffect } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import PlayNow from "./pages/PlayNow";
import GamePage from "./pages/GamePage";
import OddsPage from './pages/OddsPage';
import PregameReview from "./pages/PregameReviewPage";
import EndgamePage from './pages/EndgamePage';
import HowToPlay from "./pages/HowToPlayPage";
import PregameQuestionsPage from './pages/PregameQuestionsPage';
import ProfilePage from './pages/ProfilePage';
import PregameOddsPage from './pages/PregameOddsPage';
import Nav from "./components/Global/Nav";
import Amplify from 'aws-amplify';
// import { Auth } from 'aws-amplify';
// import { useCookies } from 'react-cookie';

function App() {
    // const [cookies, setCookie, removeCookie] = useCookies(['awsToken']);

    useEffect(() => {
        Amplify.configure({
            Auth: {
                region: "us-west-1",
                userPoolId: "us-west-1_8Kwn5BoBk",
                userPoolWebClientId: "613u362o9guosio072aq2fud72",
            },
        });
    });

    const NavWithRouter = withRouter(props => <Nav {...props}/>);
    return (
        <BrowserRouter>
            <NavWithRouter />

            <Switch>
                <Route path="/how"> <HowToPlay /> </Route>
                <Route path="/play">
                    <PlayNow />
                </Route>
                <Route path="/game/:gameid" exact component={GamePage}/>
                <Route path="/odds/:gameid" exact component={OddsPage}/>
                <Route path="/" exact>
                    <LandingPage />
                </Route>
                <Route path="/pregame/qa/:gameid" children={<PregameQuestionsPage />}></Route>
                <Route path="/pregame/odds/:gameid" children={<PregameOddsPage />}></Route>
                <Route path="/pregameReview">
                    <PregameReview />
                </Route>
                <Route path="/endgame" exact>
                    <EndgamePage />
                </Route>
                <Route path="/profile"><ProfilePage /></Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
