import styles from "./css/PregameReviewPage.module.css";
import QuestionPanel from "../components/PregameReview/QuestionPanel";
import {useState} from 'react';
import TextOverlay from "../components/Global/TextOverlay";

function PregameQuestionsPage() {
    // TODO: use collapsible component here
    const [show, setShow] = useState(true)
    const questions = [
        {
            "ID": 0,
            "question_text": "Will ETH go below $2180.59 and above $100 during the live stream?",
            "overarching": true,
            "answers": [
                {
                    "answerID": 0,
                    "answer_text": "No"
                },
                {
                    "answerID": 1,
                    "answer_text": "Maybe"
                },
                {
                    "answerID": 2,
                    "answer_text": "Yes"
                }
            ]
        }
    ]

    return (
        <div className={styles.page}>
            {/* VERTICAL NAV HERE */}
            <div className={styles.container}>
                <div className={styles.content}>
                    <TextOverlay textClass={styles.title}>WE ARE PREGAMING</TextOverlay>
                    <button onClick={()=>setShow(!show)} className={styles.toggleFeatureContainer}>
                        <p className={styles.dropDownArrow}>^</p>
                        <p className={styles.dropDownTitle}>REVIEW YOUR ANSWERS</p>
                        <div className={styles.submitButton}>SUBMIT</div>
                    </button>
                    {show &&
                        <div className={styles.reviewQuestionsContainer}>
                            <QuestionPanel questions={questions}/>
                            <QuestionPanel questions={questions}/>
                            <QuestionPanel questions={questions}/>
                            <QuestionPanel questions={questions}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PregameQuestionsPage