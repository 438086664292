import styles from './css/OddsHeaderPanel.module.css';

function PregameOddsHeader(props) {
    const { teams, matchRounds } = props;

    return (
        <div className={styles.matchup}>
            <div className={styles.team}>
                <img src={teams[0].image} className={styles.teamIcon} alt={teams[0].name} />
                <p className={styles.teamText}>{teams[0].name}</p>
            </div>
            <div className={styles.vsContainer}>
                <p className={styles.vsText}>vs</p>
                <p className={styles.bestOfText}>Best of {matchRounds}</p>
            </div>
            <div className={styles.team}>
                <img src={teams[1].image} className={styles.teamIcon} alt={teams[1].name} />
                <p className={styles.teamText}>{teams[1].name}</p>
            </div>
        </div>
    );
}

export default PregameOddsHeader;
