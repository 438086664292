import styles from "./css/ProfilePage.module.css";
import ProfileBanner from '../components/Profile/ProfileBanner.js';
import {useState} from 'react';

import ProfileSettings from '../components/Profile/ProfileSettings.js';
import ProfilePrizes from '../components/Profile/ProfilePrizes.js';
import ProfilePredictions from '../components/Profile/ProfilePredictions.js';

function ProfilePage() {
    const [tabIndex, setTabIndex] = useState(0);
    const tabs = [<ProfileSettings />, <ProfilePredictions />, <ProfilePrizes />];

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ProfileBanner setTab={setTabIndex.bind(this)} username="dcy355" location="Berkeley, California" dateJoined="June 10, 2021"/>
                { tabs[tabIndex] }
            </div>
        </div>
    )
}

export default ProfilePage
