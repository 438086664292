import styles from './css/GameList.module.css';
import GameRow from './GameRow';
import React, { useState, useEffect, useRef } from "react";

function GameList(props) {
    const { games, isInPlay } = props; // Receive isInPlay prop

    const websocketRefs = useRef([]);
    const timeoutElapsedRef = useRef(false);
    const [showButtons, setShowButtons] = useState(false);
    const [dataReady, setDataReady] = useState(false); // Track if data is ready
    const [matchids, setMatchids] = useState([]); // Manage matchids within state

    useEffect(() => {
        if (!isInPlay) {
            setDataReady(true); // Instantly mark data as ready for non in-play games
            return;
        }

        // Set a timeout to show buttons after 75 seconds
        const buttonTimeout = setTimeout(() => {
            setShowButtons(true);
            timeoutElapsedRef.current = true;
            console.log("Buttons are now visible after 75 seconds.");
        }, 75000);

        return () => clearTimeout(buttonTimeout); // Cleanup timeout on unmount
    }, [isInPlay]);

    useEffect(() => {
        if (!isInPlay) return; // Skip WebSocket setup for non in-play games

        // Close WebSocket connections after 75 seconds
        const closeTimeout = setTimeout(() => {
            closeWebsockets();
            console.log('WebSocket connections closed after 75 seconds.');
        }, 75000);

        return () => {
            clearTimeout(closeTimeout);
            closeWebsockets(); // Cleanup WebSocket connections on unmount
        };
    }, [isInPlay]);

    useEffect(() => {
        if (!isInPlay) return; // Skip WebSocket setup for non in-play games

        const fetchWebSocketData = (url) => {
            if (timeoutElapsedRef.current) return;

            const websocket = new WebSocket(url);

            websocket.onopen = () => {
                console.warn(`WebSocket connection to ${url} established.`);
            };

            websocket.onmessage = (event) => {
                console.log(`Received message from websocket ${url}:`);
                try {
                    const qa_response = JSON.parse(event.data);
                    const newMatchids = qa_response.body
                        .filter(item => item.matchid)
                        .map(item => item.matchid);
                    
                    setMatchids(prevIds => {
                        // Add only unique matchids
                        const uniqueMatchids = newMatchids.filter(id => !prevIds.includes(id));
                        return [...prevIds, ...uniqueMatchids];
                    });            
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };
            

            websocket.onerror = (error) => {
                console.error(`WebSocket error on ${url}:`, error);
            };

            websocket.onclose = () => {
                console.warn(`WebSocket connection to ${url} closed.`);
                setDataReady(true); // Set data as ready if WebSocket closes without data
            };

            websocketRefs.current.push(websocket);
        };

        // Open WebSocket connections only for in-play games
        ["wss://rimbleliveanalytics.com/odds-valorant", 
         "wss://rimbleliveanalytics.com/odds-lol", 
         "wss://rimbleliveanalytics.com/odds-csgo"].forEach(fetchWebSocketData);

        // Cleanup
        return () => {
            closeWebsockets();
        };
    }, [isInPlay]);

    const closeWebsockets = () => {
        websocketRefs.current.forEach(ws => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        });
        websocketRefs.current = []; // Reset the WebSocket references
    };

    const gameRows = dataReady ? games.map((game, index) => {
        const key = game.gameid || index; // Use index as fallback if gameid is not available
        return <GameRow key={key} game={game} matchids={matchids} showButtons={showButtons} />;
    }) : isInPlay ? <div>Loading game data...</div> : games.map((game, index) => {
        const key = game.gameid || index;
        return <GameRow key={key} game={game} showButtons={showButtons} />;
    });

    return (
        <div className={styles.listContainer}>
            { gameRows }
        </div>
    );
}

export default GameList;