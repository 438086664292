import React, { useState, useEffect } from 'react';
import styles from "./css/PregameOddsPage.module.css";
import PregameOddsHeader from "../components/Pregame/PregameOddsHeader";
import OddsPanel from "../components/Pregame/OddsPanel";
import OddsSidebar from "./OddsSidebar";
import { useParams } from 'react-router';

function PregameOddsPage() {
    const { gameid } = useParams();
    const [selectedOdds, setSelectedOdds] = useState(() => {
        return JSON.parse(localStorage.getItem('selectedOdds')) || [];
    });
    const [oddsList, setOddsList] = useState([]);
    const [totalRounds, setTotalRounds] = useState(0);
    const [matchRounds, setMatchRounds] = useState(0);
    const [teams, setTeams] = useState([]);
    const [teamMap, setTeamMap] = useState({}); // Initialize teamMap

    useEffect(() => {
        const fetchOdds = async () => {
            try {
                const lolResponse = await fetch(`https://rimbleanalytics.com/odds/lol/upcoming-matches/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: { 'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY },
                    redirect: 'follow'
                });
                const lolJson = await lolResponse.json();
                if (lolJson && lolJson[0].odds.markets) {
                    const playerMap = {};
                    const teamMapTemp = {}; // Temporary map

                    lolJson[0].teams.forEach((team, teamIndex) => {
                        teamMapTemp[team.name] = teamIndex;
                        team.players.forEach((player, playerIndex) => {
                            const playerId = teamIndex * 5 + playerIndex;
                            playerMap[player.username] = playerId.toString();
                        });
                    });

                    console.log(playerMap);

                    setTeamMap(teamMapTemp); // Set the teamMap

                    const updatedOddsList = lolJson[0].odds.markets.map((odds, index) => ({
                        ...odds,
                        id: `${gameid}_${index}`,
                        game_type: 'lol',
                        identifier: Object.entries(odds.identifier).reduce((acc, [key, value]) => {
                            if (key === "player" && playerMap[value]) {
                                acc[key] = value;
                                acc["playerID"] = playerMap[value];
                            } else {
                                acc[key] = value;
                            }
                            return acc;
                        }, {}),
                        num_selections: odds.selections.length,
                        selections: odds.selections.map((selection, selectionIndex) => ({
                            ...selection,
                            game_type: 'lol',
                            id: `${gameid}_${index}_${selectionIndex}`
                        }))
                    }));
                    setMatchRounds(lolJson[0].match_format.count);
                    setOddsList(updatedOddsList);
                    setTotalRounds(updatedOddsList.length);
                    setTeams(lolJson[0].teams);
                    return;
                }
            } catch (error) {
                console.error("Error fetching LOL odds:", error);
            }

            try {
                const valorantResponse = await fetch(`https://rimbleanalytics.com/odds/valorant/upcoming-matches/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: { 'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY },
                    redirect: 'follow'
                });
                const valorantJson = await valorantResponse.json();
                if (valorantJson && valorantJson[0].odds.markets) {
                    const playerMap = {};
                    const teamMapTemp = {};

                    valorantJson[0].teams.forEach((team, teamIndex) => {
                        teamMapTemp[team.name] = teamIndex;
                        team.players.forEach((player, playerIndex) => {
                            const playerId = teamIndex * 5 + playerIndex;
                            playerMap[player.username] = playerId.toString();
                        });
                    });

                    console.log(playerMap);

                    setTeamMap(teamMapTemp);

                    const updatedOddsList = valorantJson[0].odds.markets.map((odds, index) => ({
                        ...odds,
                        id: `${gameid}_${index}`,
                        game_type: 'valorant',
                        identifier: Object.entries(odds.identifier).reduce((acc, [key, value]) => {
                            if (key === "player" && playerMap[value]) {
                                acc[key] = value;
                                acc["playerID"] = playerMap[value];
                            } else {
                                acc[key] = value;
                            }
                            return acc;
                        }, {}),
                        num_selections: odds.selections.length,
                        selections: odds.selections.map((selection, selectionIndex) => ({
                            ...selection,
                            game_type: 'valorant',
                            id: `${gameid}_${index}_${selectionIndex}`
                        }))
                    }));
                    setMatchRounds(valorantJson[0].match_format.count);
                    setOddsList(updatedOddsList);
                    setTotalRounds(updatedOddsList.length);
                    setTeams(valorantJson[0].teams);
                    return;
                }
            } catch (error) {
                console.error("Error fetching Valorant odds:", error);
            }

            try {
                const csgoResponse = await fetch(`https://rimbleanalytics.com/odds/csgo/upcoming-matches/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: { 'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY },
                    redirect: 'follow'
                });
                const csgoJson = await csgoResponse.json();
                if (csgoJson && csgoJson[0].odds.markets) {
                    const playerMap = {};
                    const teamMapTemp = {};

                    csgoJson[0].teams.forEach((team, teamIndex) => {
                        teamMapTemp[team.name] = teamIndex;
                        team.players.forEach((player, playerIndex) => {
                            const playerId = teamIndex * 5 + playerIndex;
                            playerMap[player.username] = playerId.toString();
                        });
                    });

                    console.log(playerMap);

                    setTeamMap(teamMapTemp);

                    const updatedOddsList = csgoJson[0].odds.markets.map((odds, index) => ({
                        ...odds,
                        id: `${gameid}_${index}`,
                        game_type: 'csgo',
                        identifier: Object.entries(odds.identifier).reduce((acc, [key, value]) => {
                            if (key === "player" && playerMap[value]) {
                                acc[key] = value;
                                acc["playerID"] = playerMap[value];
                            } else {
                                acc[key] = value;
                            }
                            return acc;
                        }, {}),
                        num_selections: odds.selections.length,
                        selections: odds.selections.map((selection, selectionIndex) => ({
                            ...selection,
                            game_type: 'csgo',
                            id: `${gameid}_${index}_${selectionIndex}`
                        }))
                    }));
                    setMatchRounds(csgoJson[0].match_format.count);
                    setOddsList(updatedOddsList);
                    setTotalRounds(updatedOddsList.length);
                    setTeams(csgoJson[0].teams);
                    return;
                }
            } catch (error) {
                console.error("Error fetching CSGO odds:", error);
            }

            try {
                const cricketResponse = await fetch(`https://rimbleanalytics.com/odds/cricket/upcoming-matches/?matchid=${gameid}`, {
                    method: 'GET',
                    headers: { 'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY },
                    redirect: 'follow'
                });
                const cricketJson = await cricketResponse.json();
                if (cricketJson && cricketJson[0].odds.markets) {
                    const playerMap = {};
                    const teamMapTemp = {};

                    cricketJson[0].teams.forEach((team, teamIndex) => {
                        teamMapTemp[team.name] = teamIndex;
                        team.players.forEach((player, playerIndex) => {
                            const playerId = teamIndex * 5 + playerIndex;
                            playerMap[player.username] = playerId.toString();
                        });
                    });

                    console.log(playerMap);

                    setTeamMap(teamMapTemp);

                    const updatedOddsList = cricketJson[0].odds.markets.map((odds, index) => ({
                        ...odds,
                        id: `${gameid}_${index}`,
                        game_type: 'cricket',
                        identifier: Object.entries(odds.identifier).reduce((acc, [key, value]) => {
                            if (key === "player" && playerMap[value]) {
                                acc[key] = value;
                                acc["playerID"] = playerMap[value];
                            } else {
                                acc[key] = value;
                            }
                            return acc;
                        }, {}),
                        num_selections: odds.selections.length,
                        selections: odds.selections.map((selection, selectionIndex) => ({
                            ...selection,
                            game_type: 'cricket',
                            id: `${gameid}_${index}_${selectionIndex}`
                        }))
                    }));
                    setMatchRounds(cricketJson[0].match_format.count);
                    setOddsList(updatedOddsList);
                    setTotalRounds(updatedOddsList.length);
                    setTeams(cricketJson[0].teams);
                    return;
                }
            } catch (error) {
                console.error("Error fetching Cricket odds:", error);
            }
        };

        fetchOdds();
    }, [gameid]);

    useEffect(() => {
        console.log("Odds List Updated:", oddsList);
    }, [oddsList]);

    useEffect(() => {
        localStorage.setItem('selectedOdds', JSON.stringify(selectedOdds));
    }, [selectedOdds]);

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.titleContainer}>
                        <p className={styles.title}>WE ARE PREGAMING</p>
                        <p className={styles.titleShadow}>WE ARE PREGAMING</p>
                    </div>
                    {teams.length === 2 && (
                        <PregameOddsHeader teams={teams} matchRounds={matchRounds} />
                    )}
                    <div className={styles.mainContent}>
                        <div className={styles.oddsPanel}>
                            <OddsPanel odds={oddsList} matchRounds={matchRounds} selectedOdds={selectedOdds} setSelectedOdds={setSelectedOdds} />
                        </div>
                        <div className={styles.oddsSidebar}>
                            <OddsSidebar teams={teams} odds={oddsList} teamMap={teamMap} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PregameOddsPage;
