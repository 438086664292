import styles from './css/VerticalNav.module.css';
import { NavLink } from 'react-router-dom';

import {FiHome} from 'react-icons/fi';
import {CgProfile} from 'react-icons/cg'
import {BiBarChartAlt2, BiMapAlt} from 'react-icons/bi';
import {RiHistoryFill, RiQuestionLine} from 'react-icons/ri';

function VerticalNav(props) {
    return (
        <div className={styles.verticalNavContainer}>
            <NavLink to={`/game/${props.gameid}`} className={styles.iconContainer} activeClassName={styles.activeIconContainer}>
                <FiHome className={styles.icon}/>
            </NavLink>
            <NavLink to="/profile" className={styles.iconContainer} activeClassName={styles.activeIconContainer}>
                <CgProfile className={styles.icon}/>
            </NavLink>
            <NavLink to="/leaderboard" className={styles.iconContainer} activeClassName={styles.activeIconContainer}>
                <BiBarChartAlt2 className={styles.icon}/>
            </NavLink>
            <NavLink to="/how" className={styles.iconContainer} activeClassName={styles.activeIconContainer}>
                <BiMapAlt className={styles.icon}/>
            </NavLink>
            <NavLink to="/history" className={styles.iconContainer} activeClassName={styles.activeIconContainer}>
                <RiHistoryFill className={styles.icon}/>
            </NavLink>
            <NavLink to="/help" className={styles.iconContainer} activeClassName={styles.activeIconContainer}>
                <RiQuestionLine className={styles.icon}/>
            </NavLink>
        </div>
    )
}

export default VerticalNav;