import styles from "./css/OAuthButton.module.css";

function OAuthButton(props) {
    return (
        <div className={styles.container} style={{borderColor: props.color}}>
            <img className={styles.logo} src={props.img}></img>
            <p className={styles.text}>Sign up with {props.service}</p>
        </div>
    );
}

export default OAuthButton;
