import React, { useState, useEffect } from "react";
import styles from "./css/OddsRow.module.css";

function OddsRow(props) {
  const {
    question,
    duration,
    handleSelect,
    betSelected,
    optionSelected,
    persistent,
    answer,
    bet,
    answeredQuestion,
  } = props;

  const [timerExpired, setTimerExpired] = useState(false);

  //alert(question);
  // console.log("question displayed", question)

  function answer_functions(answerid) {
    handleSelect(question, answerid);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!persistent && !answer) {
        setTimerExpired(true);
      }
    }, duration * 1000);
    return () => clearTimeout(timer);
  }, [duration, answer, persistent]);

  // Return null if timer has expired
  if (timerExpired) {
    return null;
  }

  var outcomes = [];
  if (answer) {
    if ("team_name" in question.outcomes[0]) {
      outcomes.push(question.result.team_name);
    } else {
      outcomes.push(question.result.outcome);
    }
  } else {
    if ("team_name" in question.outcomes[0]) {
      outcomes.push(
        question.outcomes[0].team_name,
        question.outcomes[1].team_name
      );
    } else {
      outcomes.push(question.outcomes[0].outcome, question.outcomes[1].outcome);
    }
  }

  if (answer) {
    return (
      <div
        className={styles.container}
        style={{
          backgroundColor:
            question.identifier.id === betSelected ? "#0a3a6b" : "#04284d",
        }}
      >
        <div className={styles.content}>
          {/* <div className={styles.paginationText}>{questionId + 1}</div> */}
          <p className={styles.questionText}>{question.helper_text}</p>
          <div className={styles.optionContainer}>
            <div className={styles.answerText}>
              Correct Answer: {outcomes[0].toUpperCase()}
            </div>
          </div>
          {bet ? (
            outcomes[bet.optionSelected] === question.result.outcome ? (
              <div className={styles.optionContainer}>
                <div className={styles.answerText}>
                  Congrats! You earned $
                  {(bet.betAmount * bet.betOdd).toFixed(2)}
                </div>
              </div>
            ) : (
              <div className={styles.optionContainer}>
                <div className={styles.answerText}>
                  Try next time. You lost ${bet.betAmount.toFixed(2)}
                </div>
              </div>
            )
          ) : null}
        </div>
        <div className={styles.timer}>
          <div
            className={styles.shrinkingContainer}
            style={{ "--duration": duration }}
          >
            <div className={styles.shrinkingBackground}></div>
          </div>
        </div>
      </div>
    );
  }

  //alert("bot quest inside return");

  return (
    <div
      className={styles.container}
      style={{
        backgroundColor:
          question.identifier.id === betSelected ? "#0a3a6b" : "#04284d",
      }}
    >
      <div className={styles.content}>
        <p className={styles.questionText}>{question.helper_text}</p>
        {answeredQuestion != null ? (
          <div className={styles.optionContainer}>
            <div className={styles.answerText}>
              Your answer: {outcomes[answeredQuestion.answerid].toUpperCase()}
            </div>
          </div>
        ) : (
          <div className={styles.optionContainer}>
            <div
              className={styles.optionText}
              onClick={() => answer_functions(0)}
              style={{
                background:
                  question.identifier.id === betSelected && optionSelected === 0
                    ? "linear-gradient(180deg, #6458be 0%, #8556c6 50%)"
                    : "linear-gradient(180deg, #3C3575 0%, #563189 50%)",
              }}
            >
              {outcomes[0].toUpperCase() +
                ` [${question.outcomes[0].decimal_odd.toFixed(2)}x]`}
            </div>
            <div
              className={styles.optionText}
              onClick={() => answer_functions(1)}
              style={{
                background:
                  question.identifier.id === betSelected && optionSelected === 1
                    ? "linear-gradient(180deg, #6458be 0%, #8556c6 50%)"
                    : "linear-gradient(180deg, #3C3575 0%, #563189 50%)",
              }}
            >
              {outcomes[1].toUpperCase() +
                ` [${question.outcomes[1].decimal_odd.toFixed(2)}x]`}
            </div>
          </div>
        )}
      </div>

      <div className={styles.timer}>
        <div
          className={styles.shrinkingContainer}
          style={{ "--duration": duration }}
        >
          <div className={styles.shrinkingBackground}></div>
        </div>
      </div>
    </div>
  );
}

export default OddsRow;
