import styles from './css/BottomAnswer.module.css';

function BottomAnswer(props) {
    const { answer, duration, questionId } = props;

    //alert("bot quest inside return");
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.paginationText}>Answer for question {questionId + 1}: "{answer.question_text}" was {answer.correct_answer_text}!</div>
                {/* <p className={styles.questionText}>{answer.question_text} was </p> */}
                
            </div>

            <div className={styles.timer}>
                <div className={styles.shrinkingContainer} style={{"--duration": duration}}>
                    <div className={styles.shrinkingBackground}></div>
                </div> 
            </div>
        </div>
    );
}

export default BottomAnswer;
