import styles from './css/ProfileSettings.module.css';
import {BsPersonSquare, BsBoxArrowUpRight} from 'react-icons/bs';
import {MdEmail, MdLocationOn} from 'react-icons/md';
import {RiYoutubeFill, RiTwitchFill, RiDiscordFill} from "react-icons/ri";
import ProfileChange from './ProfileChange'
import SocialAccount from './SocialAccount';

function ProfileSettings() {
    const socialConnections = {
        "Twitch": {
            "connected": true,
            "logo": <RiTwitchFill style={{color: '#9146FF', fontSize: '30px'}}/>
        },
        "YouTube": {
            "connected": false,
            "logo": <RiYoutubeFill style={{color: '#F50E02', fontSize: '30px'}}/>
        },
        "Discord": {
            "connected": false,
            "logo": <RiDiscordFill style={{color: '#5165F6', fontSize: '30px'}}/>
        }
    }

    const connectedSection = []
    const notConnectedSection = []

    for (let platformName in socialConnections) {
        let platform = socialConnections[platformName]
        let isConnected = platform["connected"]
        let content = <SocialAccount name={platformName} logo={platform["logo"]} isConnected={isConnected} />
        isConnected ? connectedSection.push(content) : notConnectedSection.push(content);
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <p className={styles.title}>PROFILE SETTINGS</p>
                <p className={styles.subtitle}>Change identifying information and details about your account.</p>
                <ProfileChange icon={<BsPersonSquare/>} title="Name" subtitle="Visible to the public"/>
                <ProfileChange icon={<BsBoxArrowUpRight/>} title="Display Name" subtitle="Visible to the public"/>
                <ProfileChange icon={<MdLocationOn/>} title="Location" subtitle="Where you at?"/>
                <ProfileChange icon={<MdEmail/>} title="Email" subtitle=""/>
            </div>

            <div className={styles.right}>
                <p className={styles.title}>CONNECT TO SOCIAL ACCOUNTS</p>
                <div className={styles.connectedContainer}>
                    <p className={styles.socialSubtitle}>Connected Accounts</p>
                    {connectedSection}
                </div>
                <div className={styles.notConnectedContainer}>
                    <p className={styles.socialSubtitle}>Connect More Accounts</p>
                    {notConnectedSection}
                </div>
            </div>
        </div>
    )
}

export default ProfileSettings
