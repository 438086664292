function TwitchChat(props) {
    const {channel, height, width} = props;

    return (
        <iframe
            id="chat_embed"
            src={"https://www.twitch.tv/embed/" + channel + "/chat?parent=rimble.gg&parent=localhost&darkpopout"}
            height={height}
            width={width}
            frameBorder="0px"
        >          
        </iframe>
    )
}

export default TwitchChat