import styles from "./css/EndgamePage.module.css";
import WinnerPanel from "../components/Endgame/WinnerPanel";
import RedeemPanel from "../components/Endgame/RedeemPanel";
import CollapsibleResults from "../components/Endgame/CollapsibleResults";
import TextOverlay from "../components/Global/TextOverlay";

function EndgamePage() {

    const data = [
        {
            "ID": 0,
            "question_text": "Which team will have the player with most cs by the end of the game?",
            "overarching": true,
            "answers": [
                {
                    "answerID": 0,
                    "answer_text": "Red"
                },
                {
                    "answerID": 1,
                    "answer_text": "Blue"
                }
            ],
            "correct_answer": {
                "answerID": 0,
                "answer_text": "Red"
            },
            "user_answer": {
                "answerID": 0,
                "answer_text": "Red"
            }
        },
        {
            "ID": 1,
            "question_text": "Will ETH go below $2180.59 during the live stream?",
            "overarching": true,
            "answers": [
                {
                    "answerID": 0,
                    "answer_text": "Yes"
                },
                {
                    "answerID": 1,
                    "answer_text": "Maybe"
                },
                {
                    "answerID": 2,
                    "answer_text": "No"
                }
            ],
            "correct_answer": {
                "answerID": 1,
                "answer_text": "Maybe"
            },
            "user_answer": {
                "answerID": 2,
                "answer_text": "No"
            }
        }
    ]

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <TextOverlay textClass={styles.title}>THE ENDGAME</TextOverlay>
                <WinnerPanel winner="UC Berkeley"/>
                <RedeemPanel />
                <CollapsibleResults title="PREGAME QUESTIONS" data={data}/>
                <CollapsibleResults title="INGAME QUESTIONS" data={data}/>
            </div>
        </div>
    )
}

export default EndgamePage
