import React, { useState } from 'react';
// import { useEffect } from 'react';
import styles from './css/Login.module.css';
// import OAuthButton from './OAuthButton';
import twitchLogo from '../../assets/twitch.svg';
import ytLogo from '../../assets/youtube.svg';
import discordLogo from '../../assets/discord.svg';
import { Auth } from 'aws-amplify';
import { useCookies } from 'react-cookie';
import rimbleLogo from '../../assets/rimbleLogo.svg';
// import emailLogo from '../../assets/email.svg';
// import hideLogo from '../../assets/hide.svg';
import TextOverlay from "../Global/TextOverlay";

function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [/* cookies */ , setCookie, /* removeCookie */] = useCookies(['awsToken']);

    const { setLoginOpen } = props;

    async function signIn() {
        try {
            const user = await Auth.signIn(username, password);
            console.log(user);
            console.log(Auth.currentSession());
            Auth.currentSession().then(data => {
                console.log(data.accessToken.jwtToken)
                setCookie('awsToken', data.accessToken.jwtToken, { path: '/' });
                setLoginOpen(false);
            });
        } catch (error) {
            setError("Wrong username or password!");
            console.log('error signing in', error);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <img src={rimbleLogo} className={styles.rimbleLogo}></img>
            </div>
            <div className={styles.right}>
                <div className={styles.rightSideContent}>
                    <TextOverlay textClass={styles.title}>SIGN IN</TextOverlay>
                    {error && <p className={styles.error}>Error: {error}</p>}
                    <p className={styles.header}>Email</p>
                    <input type="text" className={styles.input} id={styles.emailLogo} value={username} onChange={e => setUsername(e.target.value)}/>
                    <p className={styles.header}>Password</p>
                    <input type="password" className={styles.input} id={styles.passwordLogo} value={password} onChange={e => setPassword(e.target.value)}/>
                    <div className={styles.termsContainer}>
                        <div className={styles.otherOptions}>
                            <p className={styles.loginOther}>Or continue with the following options:</p>
                            <img src={ytLogo} className={styles.logo}></img>
                            <img src={twitchLogo} className={styles.logo}></img>
                            <img src={discordLogo} className={styles.logo}></img>
                        </div>
                        <div className={styles.rememberContainer}>
                            <input type="checkbox" id="login-remember-me" className={styles.termsCheckbox}/>
                            <label className={styles.termsText} htmlFor="login-remember-me">Remember me</label>
                        </div>
                        {/* Turn into a href= */}
                        <a className={styles.forgotPassword}>
                            Forgot your Password?
                        </a>
                    </div>
                    <p className={styles.signUp}>Interested in joining? <a href="components/auth/register.js" className={styles.redirectLink}>Sign up here</a></p>
                    <div className={styles.actionBtn} onClick={signIn}>Sign in</div>
                </div>
            </div>
        </div>
    )
}

export default Login;
