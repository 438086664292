import styles from './css/QuestionPanel.module.css';
// import {useState} from 'react';
import QuestionCard from './QuestionCard';
// import {BsCaretLeftFill, BsCaretRightFill} from 'react-icons/bs'

function QuestionPanel(props) {
    const {questions} = props;

    const questionCards = []
    for (let question of questions) {
        questionCards.push(<QuestionCard questionInfo={question} />)
    }
    return (
        <div className={styles.container}>
            {questionCards}
        </div>
    )
}

export default QuestionPanel
