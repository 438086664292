import styles from "./css/CollapsibleResults.module.css";
import {useState} from 'react';
import {RiArrowDownSLine, RiArrowRightSLine, } from 'react-icons/ri';
import QuestionResult from "./QuestionResult";

function CollapsibleResults(props) {
    const {title, data} = props;
    const [isShowing, setIsShowing] = useState(true);

    const results = []
    for (let question of data) {
        results.push(
            <QuestionResult
                question={question["question_text"]}
                answerChoices={question["answers"]}
                correctAnswer={question["correct_answer"]}
                userAnswer={question["user_answer"]}
            />
        );
    }

    const CollapseArrow = isShowing ? RiArrowDownSLine : RiArrowRightSLine;

    return (
        <div className={styles.container}>
            <div className={styles.collapsed}>
                <CollapseArrow className={styles.collapseIcon} onClick={() => setIsShowing(!isShowing)}/>
                <p className={styles.title}>{title}</p>
                <p className={styles.score}>SCORE: 8/24</p>
            </div>
            <div className={styles.uncollapsed}>
                {isShowing && results}
            </div>
        </div>
    )
}

export default CollapsibleResults
