import styles from "./css/GamePage.module.css";
import { useParams } from "react-router";
import React, { useState, useEffect, useCallback, useRef, memo } from "react";
// import PopupQuestion from "../components/Stream/PopupQuestion";
import VerticalNav from "../components/Stream/VerticalNav";
import TwitchStream from "../components/Stream/TwitchStream";
// import GameSidebar from "../components/Stream/GameSidebar";
import BottomQuestion from "../components/Stream/BottomQuestion";
import BottomAnswer from "../components/Stream/BottomAnswer";
import OddsGameSidebar from "../components/Stream/OddsGameSidebar";

const MemoizedOddsGameSidebar = memo(OddsGameSidebar);




const demoQuestion1 = {
  question_id: 0,
  question_text: "In round 14, which team will get the first death?",
  question_type: "question_team_stats",
  overarching: true,
  answers: [
    {
      answerID: 0,
      answer_text: "VAL-1",
    },
    {
      answerID: 1,
      answer_text: "VAL-2",
    },
  ],
  question_metadata: {
    answer_type: "answer_team_with_first_stat",
    stat: "first_death",
  },
  team1_name: "VAL-1",
  team2_name: "VAL-2",
  identifier: {
    market_type: "round_first_stat",
    stat: "first_death",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 14,
  },
};

const demoQuestion2 = {
  question_id: 1,
  question_text: "In round 17, which team will get the first death?",
  question_type: "question_team_stats",
  overarching: true,
  answers: [
    {
      answerID: 0,
      answer_text: "VAL-1",
    },
    {
      answerID: 1,
      answer_text: "VAL-2",
    },
  ],
  question_metadata: {
    answer_type: "answer_team_with_first_stat",
    stat: "first_death",
  },
  team1_name: "VAL-1",
  team2_name: "VAL-2",
  identifier: {
    market_type: "round_first_stat",
    stat: "first_death",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 17,
  },
};

const demoAnswer = {
  question_id: 0,
  question_text: "In round 14, which team will get the first death?",
  question_type: "question_team_stats",
  overarching: true,
  answers: [
    {
      answerID: 0,
      answer_text: "VAL-1",
    },
    {
      answerID: 1,
      answer_text: "VAL-2",
    },
  ],
  question_metadata: {
    answer_type: "answer_team_with_first_stat",
    stat: "first_death",
  },
  identifier: {
    market_type: "round_first_stat",
    stat: "first_death",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 14,
  },
  team1_name: "VAL-1",
  team2_name: "VAL-2",
  correct_answer_ID: 0,
  correct_answer_text: "VAL-1",
  successful: true,
};

function GamePage(props) {
  const videoRef = useRef(null);
  const { gameid } = useParams();
  const userid = "testwebsitereactuser";
  // const matchid = "999999999";
  // const [freshpage, setFreshpage] = useState(true);
  const [unansweredQuestions, /*setUnansweredQuestions*/] = useState([]);
  const [persistentQuestions, /*setPersistentQuestions*/] = useState({});
  const [answers, /*setAnswers*/] = useState([]);
  const [askedQuestions, /*setAskedQuestions*/] = useState({});
  const [twitchChannel, setTwitchChannel] = useState("");
  const [videoReady, setVideoReady] = useState(false);
  const [currentjson, setCurrentJson] = useState({
    ingame: {
      games: [
        {
          unanswered_questions: {
            // 0: {
            //   question_text:
            //     "Who will get the ball first?",
            //   answers: [
            //     { answer_text: "GUAPO   [1.5x]", answer_id: 0 },
            //     { answer_text: "VISITOR [2.3x]", answer_id: 1 },
            //   ],
            //   odds: [
            //     { odds_val: "1.7", odds_id: 0},
            //     { odds_val: "1.6", odds_id: 1},
            //   ],
            // },
            // 1: {
            //   "ID": 8,
            //     "question_text": "In round 10, which team will get the first kill?",
            //     "question_type": "question_team_stats",
            //     "overarching": true,
            //     "answers": [
            //         {
            //             "answerID": 0,
            //             "answer_text": "VAL-1"
            //         },
            //         {
            //             "answerID": 1,
            //             "answer_text": "VAL-2"
            //         }
            //     ],
            //     "question_metadata": {
            //     "answer_type": "answer_team_with_first_stat",
            //         "stat_name": "first_kill",
            //         "round_num": 10,
            //         "game_num": 3
            //     },
            //     "team1": "VAL-1",
            //     "team2": "VAL-2"
            // },
            // 2: {
            //   question_text:
            //     "How many rebounds will we have in the next round?",
            //   answers: [
            //     { answer_text: "Less than 1 [1.5x]", answer_id: 0 },
            //     { answer_text: "More than 1 [2.3x]", answer_id: 1 },
            //   ],
            //   odds: [
            //     { odds_val: "1.7", odds_id: 0},
            //     { odds_val: "1.6", odds_id: 1},
            //   ],
            // },
            // 3: {
            //   question_text:
            //     "Will GUAPO score in the next round?",
            //   answers: [
            //     { answer_text: "Yes [1.5x]", answer_id: 0 },
            //     { answer_text: "No  [2.3x]", answer_id: 1 },
            //   ],
            //   odds: [
            //     { odds_val: "1.7", odds_id: 0},
            //     { odds_val: "1.6", odds_id: 1},
            //   ],
            //
            // },
          },
          answers: {
            // 0: {
            // "questionID": 11, "question_text": "Who will get the ball first?",  "correct_answer_text": "VISITOR", "successful": true
            // },
            // 1: {
            //   "questionID": 12, "question_text": "In round 10, which team will get the first kill?",  "correct_answer_text": "VAL-2", "successful": true
            // },
          },
          asked_questions: {},
        },
      ],
    },
  });
  // const [currentId, setCurrentId] = useState(0);
  // const [askedQuestions, setAskedQuestions] = useState({});
  const [newQuestionGameId /*, setNewQuestionGameId */] = useState(0);
  // const [newQuestionGameIdStr, setNewQuestionGameIdStr] = useState("");
  const [newQuestionQuestionId, setNewQuestionQuestionId] = useState(0);

  // const [countdown, setCountdown] = useState(4);

  const [timer, setTimer] = useState(0);
  const [questionIntervals, setQuestionIntervals] = useState({});
  // {
  //   0: 20,
  //   1: 110,
  //   2: 200,
  // }

  const [showQuestionWindow, setShowQuestionWindow] = useState(false);
  const [showAnswerWindow, setShowAnswerWindow] = useState(false);

  const incrementTimer = useCallback(() => {
    setTimer((timer) => timer + 1);
  }, []);

  useEffect(() => {
    const timeoutFunction = setInterval(() => {
      incrementTimer();
    }, 1000);
    return () => clearInterval(timeoutFunction);
  }, [incrementTimer, timer]);

  const questionAnswered = useCallback((answerid, questionId) => {
    // console.log("answerid selected ", answerid, "questionId", questionId);
    setShowQuestionWindow(false);

    if (questionId in currentjson.ingame.games[newQuestionGameId].asked_questions) {
      return
    }
    setNewQuestionQuestionId(newQuestionQuestionId + 1);
    const current_game = currentjson.ingame.games[newQuestionGameId]

    const updatedAskedQuestions = {
      ...current_game.asked_questions,
      [questionId]: {
        answerid: answerid,
        question:
          currentjson.ingame.games[newQuestionGameId].unanswered_questions[
            questionId
          ],
      },
    };
    current_game.asked_questions = updatedAskedQuestions;
    setCurrentJson(currentjson);
    setQuestionIntervals({
      ...questionIntervals,
      [newQuestionQuestionId]: timer,
    });
    setNewQuestionQuestionId(newQuestionQuestionId + 1);
    // console.log("asked questions", currentjson, newQuestionQuestionId, questionIntervals);
  }, [currentjson, newQuestionGameId, newQuestionQuestionId, setShowQuestionWindow, setCurrentJson, setQuestionIntervals, questionIntervals, timer]);

  const addUnansweredQuestion = useCallback((question) => {
    // game_num = question.question_metadata.game_num

    // generate random number from 1.1 - 3.0 for odds of answer 1 and answer 2
    const answer_1_odd = (
      (Math.floor(Math.random() * (30 - 11 + 1)) + 11) /
      10
    ).toFixed(1);
    const answer_2_odd = (
      (Math.floor(Math.random() * (30 - 11 + 1)) + 11) /
      10
    ).toFixed(1);
    question.odds = [answer_1_odd, answer_2_odd];
    const current_game = currentjson.ingame.games[newQuestionGameId];
    // error with newQuestionQuestionId not getting updated since it's called in .onmessage(). Fixed it by using length
    const curr_question_num = Object.keys(
      current_game.unanswered_questions
    ).length;
    if (
      !Object.values(current_game.unanswered_questions).some(
        (item) => item.question_id === question.question_id
      )
    ) {
      const updatedUnAnsweredQuestions = {
        ...current_game.unanswered_questions,
        [curr_question_num]: question,
      };
      current_game.unanswered_questions = updatedUnAnsweredQuestions;
      setCurrentJson(currentjson);
    }


    setShowQuestionWindow(true);

    // console.log("showing question", newQuestionQuestionId, timer, questionIntervals);
  }, [currentjson, newQuestionGameId]);


  const addAnswer = useCallback((previous_answer) => {
    // Handles the first question sent (no answer yet)
    const current_game = currentjson.ingame.games[newQuestionGameId];
    const curr_question_num = Object.keys(
      current_game.unanswered_questions
    ).length;
    if (previous_answer && curr_question_num > 1) {
      const matchQuestion = Object.keys(current_game.unanswered_questions).find(
        (key) =>
          current_game.unanswered_questions[key].question_id ===
          previous_answer.question_id
      );
      if (matchQuestion != null) {
        const updatedAnswers = {
          ...current_game.answers,
          [matchQuestion]: previous_answer,
        };
        current_game.answers = updatedAnswers;
        setCurrentJson(currentjson);
      }

      // setShowAnswerWindow(true);
    }
  }, [currentjson, newQuestionGameId]);
  useEffect(() => {
    if (showQuestionWindow) {
      setTimeout(() => {
        if (showQuestionWindow) {
          questionAnswered(null, newQuestionQuestionId);
          setShowQuestionWindow(false);
        }
      }, 20000);
    }
  }, [showQuestionWindow, questionAnswered, newQuestionQuestionId]);

  useEffect(() => {
    if (showAnswerWindow) {
      setTimeout(() => {
        if (showAnswerWindow) {
          // console.log('showing answer', timer, currentjson.ingame.games[newQuestionGameId].answers, newQuestionQuestionId);
          setShowAnswerWindow(false);
        }
      }, 20000);
    }
  }, [showAnswerWindow]);


  useEffect(() => {
    if (videoReady) {
      const video = videoRef.current;
      video.play();
    }

  }, [videoRef, videoReady]);

  /*
  const handlePlayButtonClick = () => {
    if (videoRef.current && !videoReady) {
      videoRef.current.play();
      setVideoReady(true);
    }
  };
  */


  const valorantWebSocketRef = useRef(null);
  const lolWebSocketRef = useRef(null);
  const csgoWebSocketRef = useRef(null);


  // Abstracted function to fetch Twitch link
  const fetchTwitchLink = async (url, setTwitchChannel, setVideoReady) => {
      try {
          const game_data = await fetch(url, {
              method: "GET",
              headers: { 'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY },
          });

          const game_data_json = await game_data.json();
          console.log(game_data_json);
          if (game_data_json[0].stream_links && game_data_json[0].stream_links.length) {
              setTwitchChannel(game_data_json[0].stream_links[0].split("/").pop());
              // setVideoReady(true);
          }
      } catch (error) {
          console.error('Fetch error:', error);
      }
  };

  useEffect(() => {
      const valorantUrl = "wss://rimbleliveanalytics.com/engagement-valorant";
      const lolUrl = "wss://rimbleliveanalytics.com/engagement-lol";
      const csgoUrl = "wss://rimbleliveanalytics.com/engagement-csgo";

      // Connect to Valorant WebSocket
      valorantWebSocketRef.current = new WebSocket(valorantUrl);
      valorantWebSocketRef.current.onopen = () => {
          console.log('Valorant WebSocket connection established.');
      };
      valorantWebSocketRef.current.onmessage = (event) => {
          console.log('Received message from Valorant WebSocket:', event.data);
          var qa_response = JSON.parse(event.data);
          if (qa_response.body[0].matchid === gameid) {
              console.log("Adding question-answer pair")
              addUnansweredQuestion(qa_response.body[0].current_question);
              addAnswer(qa_response.body[0].previous_answer);
          }
      };
      valorantWebSocketRef.current.onclose = () => {
          console.log('Valorant WebSocket connection closed.');
      };

      // Fetch Valorant Twitch link
      fetchTwitchLink(`https://rimbleanalytics.com/predictions/valorant/match-status?matchid=${gameid}`, setTwitchChannel, setVideoReady);


      // Connect to LoL WebSocket
      lolWebSocketRef.current = new WebSocket(lolUrl);
      lolWebSocketRef.current.onopen = () => {
          console.log('LoL WebSocket connection established.');
      };
      lolWebSocketRef.current.onmessage = (event) => {
          console.log('Received message from LoL WebSocket:', event.data);
          var qa_response = JSON.parse(event.data);
          if (qa_response.body[0].matchid === gameid) {
              addUnansweredQuestion(qa_response.body[0].current_question);
              addAnswer(qa_response.body[0].previous_answer);
          }
      };
      lolWebSocketRef.current.onclose = () => {
          console.log('LoL WebSocket connection closed.');
      };

      // Fetch LoL Twitch link
      fetchTwitchLink(`https://rimbleanalytics.com/predictions/lol/match-status?matchid=${gameid}`, setTwitchChannel, setVideoReady);

      // Connect to CSGO WebSocket
      csgoWebSocketRef.current = new WebSocket(csgoUrl);
      csgoWebSocketRef.current.onopen = () => {
          console.log('CSGO WebSocket connection established.');
      };
      csgoWebSocketRef.current.onmessage = (event) => {
          console.log('Received message from CSGO WebSocket:', event.data);
          var qa_response = JSON.parse(event.data);
          if (qa_response.body[0].matchid === gameid) {
              addUnansweredQuestion(qa_response.body[0].current_question);
              addAnswer(qa_response.body[0].previous_answer);
          }
      };
      csgoWebSocketRef.current.onclose = () => {
          console.log('CSGO WebSocket connection closed.');
      };

      // Fetch CSGO Twitch link
      fetchTwitchLink(`https://rimbleanalytics.com/predictions/csgo/match-status?matchid=${gameid}`, setTwitchChannel, setVideoReady);

      // Clean up WebSocket connections on component unmount
      return () => {
          if (valorantWebSocketRef.current) {
              valorantWebSocketRef.current.close();
          }
          if (lolWebSocketRef.current) {
              lolWebSocketRef.current.close();
          }
          if (csgoWebSocketRef.current) {
              csgoWebSocketRef.current.close();
          }
      };
  }, [gameid, addAnswer, addUnansweredQuestion]);

   useEffect(() => {
     if (
       !showQuestionWindow &&
       newQuestionQuestionId <
         Object.keys(
           currentjson.ingame.games[newQuestionGameId].unanswered_questions
         ).length
     ) {
       setShowQuestionWindow(true);
       setQuestionIntervals[newQuestionQuestionId] = timer
       // console.log("showing question", newQuestionQuestionId, timer);
    }
  }, [newQuestionQuestionId, currentjson.ingame.games, newQuestionGameId, showQuestionWindow, timer]);
  useEffect(() => {
    if (
      timer === questionIntervals[newQuestionQuestionId - 1] + 30 &&
      !showAnswerWindow &&
      newQuestionQuestionId - 2 in
        Object.keys(currentjson.ingame.games[newQuestionGameId].answers)
    ) {
      setShowAnswerWindow(true);
      // console.log("current answer", currentjson.ingame.games[newQuestionGameId].answers)
      // console.log("current question number", newQuestionQuestionId)
      // console.log("currnet jsonnn", currentjson);
      // console.log("question intervals", questionIntervals);
    }

  }, [timer, currentjson.ingame.games, newQuestionGameId, newQuestionQuestionId, questionIntervals, showAnswerWindow]);

  useEffect(() => {
    if (
      gameid === "demo" &&
      Object.keys(
        currentjson.ingame.games[newQuestionGameId].unanswered_questions
      ).length === 0
    ) {
      addUnansweredQuestion(demoQuestion1);
      setTimeout(() => {
        addUnansweredQuestion(demoQuestion2);
        addAnswer(demoAnswer);
      }, 60000);
      // setTimeout(() => {
      //   addAnswers([demoAnswer2])
      // }, 25000)
    }
  }, [addAnswer, addUnansweredQuestion, currentjson.ingame.games, gameid, newQuestionGameId]);


  return (
    <div className={styles.gameContainer}>
      <div className={styles.contentContainer}>
        <VerticalNav />
        <div className={styles.middleContent}>
          {twitchChannel ? (
            <>
              <TwitchStream channel={twitchChannel} />
              <div data-vjs-player>
                <video ref={videoRef} className="video-js"></video>
              </div>
            </>
          ) : (
            <></>
          )}
        {/*<video muted="muted" ref={videoRef} src={streamUrl} controls autoplay className={styles.videoContainer}*/}
          <div className={styles.popUpQuestion}></div>
        </div>

        <MemoizedOddsGameSidebar
          chatChannel={twitchChannel}
          flashOdds={unansweredQuestions}
          persistentOdds={persistentQuestions}
          answeredQuestions={askedQuestions}
          questionAnswered={questionAnswered}
          answers={answers}
        />
      </div>
      {currentjson &&
        currentjson.ingame &&
        showQuestionWindow &&
        currentjson.ingame.games[newQuestionGameId].unanswered_questions[
          newQuestionQuestionId
        ] && (
          <BottomQuestion
            gameId={gameid}
            duration={20}
            question={
              currentjson.ingame.games[newQuestionGameId].unanswered_questions[
                newQuestionQuestionId
              ]
            }
            questionAnswered={questionAnswered}
            matchid={gameid}
            userid={userid}
            questionId={newQuestionQuestionId}
          />
        )}
      {currentjson &&
        currentjson.ingame &&
        newQuestionQuestionId > 1 &&
        showAnswerWindow &&
        currentjson.ingame.games[newQuestionGameId].answers[
          newQuestionQuestionId - 2
        ] && (

          <BottomAnswer
            answer={
              currentjson.ingame.games[newQuestionGameId].answers[
                newQuestionQuestionId - 2
              ]
            }
            duration={20}
            questionId={newQuestionQuestionId - 2}
          />
        )}
    </div>
  );
}

export default GamePage;
