import styles from './css/SocialAccount.module.css';

function SocialAccount(props) {
    const {isConnected, logo, name} = props

    return (
        <div className={isConnected? styles.notConnectedContainer : styles.connectedContainer}>
            <div className={styles.platformContainer}>
                {logo}
                <p className={styles.name}>{name}</p>
            </div>
            <div className={styles.connectionBtn} onClick>
                {isConnected? "Remove" : "Connect"}
            </div>
        </div>
    )
}

export default SocialAccount
