import styles from "./css/WinnerPanel.module.css";
import confetti from './../../assets/confetti.png';
import winnerImg from './../../assets/winner.png';
import trophy from './../../assets/trophy.svg';

function WinnerPanel(props) {
    const {winner} = props;

    return (
        <div className={styles.container}>
            <p className={styles.title}>THE COMPETITION WINNER IS {winner}</p>
            <div className={styles.trophyContainer}>
                <div className={styles.circle}>
                    <img src={trophy} className={styles.trophy}/>
                    <img src={confetti} className={styles.confetti} />
                </div>
                <div className={styles.circle}></div>
            </div>
            <div className={styles.placeholder}>
                <img src={winnerImg} className={styles.winnerImage} />
            </div>
        </div>
    )
}

export default WinnerPanel
