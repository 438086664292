import styles from "./css/QuestionResult.module.css";
import {HiXCircle, HiCheckCircle} from 'react-icons/hi'

function QuestionResult(props) {
    const {question, answerChoices, correctAnswer, userAnswer} = props;

    const answerChoiceList = []
    for (let index in answerChoices) {
        var className = answerChoices[index]["answerID"] === correctAnswer["answerID"] ? `${styles.answerChoice} ${styles.correct}` : styles.answerChoice;
        const content = (
            <div className={className} id={answerChoices[index]["answerID"]}>
                {answerChoices[index]["answer_text"]}
                {userAnswer["answerID"] === answerChoices[index]["answerID"] && 
                    (userAnswer["answerID"] === correctAnswer["answerID"] ? 
                        <HiCheckCircle className={styles.correctIcon}/> : 
                        <HiXCircle className={styles.incorrectIcon}/>
                    )
                }
            </div>
        );
        answerChoiceList.push(content)
    }

    return (
        <div className={styles.container}>
            <p className={styles.title}>{question}</p>
            <div className={styles.results}>
                { answerChoiceList }
                <div className={styles.feedback}>
                    {correctAnswer["answerID"] === userAnswer["answerID"] ? "Good job!" : `Your answer was incorrect!`}
                </div>
            </div>
        </div>
    )
}

export default QuestionResult
