import styles from "./css/ProfileChange.module.css";
import {RiPencilFill} from 'react-icons/ri'

function ProfileChange(props) {
    const {icon, title, subtitle} = props;

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>{icon}</div>
            <div className={styles.rightContainer}>
                <div className={styles.category}>
                    <p className={styles.title}>{title}</p>
                    <p className={styles.subtitle}>{subtitle}</p>
                </div>
                <div className={styles.inputContainer}>
                    <input className={styles.input}></input>
                    <RiPencilFill className={styles.editIcon}/>
                </div>
            </div>
        </div>
    )
}

export default ProfileChange
