import React from 'react';
// import { useState } from 'react';
import styles from './css/Register.module.css';
import OAuthButton from './OAuthButton';
import twitchLogo from '../../assets/twitch.png';
import ytLogo from '../../assets/youtube.png';
import discordLogo from '../../assets/discord.png';
import TextOverlay from "../Global/TextOverlay";

function Register(props) {
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.leftSideContent}>
                    <TextOverlay textClass={styles.title}>REGISTER</TextOverlay>
                    {/* <img src={linkLogo}></img> */}
                    <div className={styles.bottomOfLeftSide}>
                        <OAuthButton img={twitchLogo} service="Twitch" color="#BC9FFF" />
                        <OAuthButton img={ytLogo} service="YouTube" color="#FF0000" />
                        <OAuthButton img={discordLogo} service="Discord" color="#7289DA" />
                        <div className={styles.rimbleSignInContainer}>
                            <p className={styles.accountSignInText}>Have an account? <a HREF="components/auth/login.js" className={styles.redirectLink}>Sign in</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.rightSideContent}>
                    <div className={styles.inputContainers}>
                        <p className={styles.header}>Set username</p>
                        <input type="text" placeholder="Keep it under 8 characters" className={styles.input}/>
                    </div>
                    <div className={styles.inputContainers}>
                        <p className={styles.header}>Your email</p>
                        <input type="text" placeholder="johndoe@email.com" className={styles.input}/>
                    </div>
                    <div className={styles.nameContainer}>
                        <div className={styles.firstNameContainer}>
                        <p className={styles.header}>First name</p>
                        <input type="password" placeholder="John" className={styles.firstName}/>
                        </div>
                        <div className={styles.lastNameContainer}>
                        <p className={styles.header}>Last name</p>
                        <input type="password" placeholder="Doe" className={styles.lastName}/>
                        </div>
                    </div>
                    <div className={styles.inputContainers}>
                        <p className={styles.header}>Select Location</p>
                        <input type="password" placeholder="United States of America" className={styles.input}/>
                    </div>
                    <div className={styles.inputContainers}>
                        <p className={styles.header}>Date of Birth</p>
                        <input type="password" placeholder="01/01/2001" className={styles.input}/>
                    </div>
                    <div className={styles.inputContainers}>
                        <p className={styles.header}>Password</p>
                        <input type="password" placeholder="Must be at least 8 characters" className={styles.input}/>
                    </div>
                    <div className={styles.termsContainer}>
                        <input type="checkbox" id="register-terms-checkbox" className={styles.termsCheckbox}/>
                        <label className={styles.termsText} htmlFor="register-terms-checkbox">I agree to all statements included in <a className={styles.redirectLink}>Terms of Use</a>.</label>
                    </div>
                    <div className={styles.actionBtn}>SIGN UP</div>
                </div>
            </div>
        </div>
    )
}

export default Register;
