import Modal from 'react-modal';
import styles from "./css/ModalWrapper.module.css";
// import { useState } from 'react'

function ModalWrapper(props) {
    const customStyles = {
        content: {
            background: 'none',
            border: 'none'
        },
        overlay: {
            zIndex: 1000,
            background: "rgb(0,0,0, 0.3)",

        }
    };

    return (
        <Modal style={customStyles} ariaHideApp={false} isOpen={props.isOpen} onRequestClose={() => props.toggleFn(false)}>
            <div className={styles.content}>
                { props.children }
                <p className={styles.close} onClick={() => props.toggleFn(false)}>&#10006;</p>
            </div>
        </Modal>
    );
}

export default ModalWrapper;
