import PropTypes from 'prop-types';
import React from 'react';
import styles from './css/TwitchStream.module.css';

const EMBED_URL = 'https://player.twitch.tv/js/embed/v1.js';

class TwitchStream extends React.Component {
    componentDidMount() {
        if (!this.props.channel) {
            console.error('No channel specified for TwitchStream.');
            return;
        }

        let embed;
        const script = document.createElement('script');
        script.setAttribute('src', EMBED_URL);
        script.addEventListener('load', () => {
            embed = new window.Twitch.Player('twitch-stream', {
                channel: this.props.channel,
                width: this.props.width,
                height: this.props.height,
                parent: ['rimble.gg']
            });
        });
        document.body.appendChild(script);
    }

    render() {
        return (
            <div id="twitch-stream" className={styles.twitchContainer}></div>
        );
    }
}

TwitchStream.propTypes = {
    channel: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string
};

TwitchStream.defaultProps = {
    width: '100%',
    height: '100%',
    channel: ''
};

export default TwitchStream;
