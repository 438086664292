import styles from "./css/TextOverlay.module.css";
// import { useState } from 'react'

function TextOverlay(props) {
    const { textClass } = props;

    return (
        <div className={styles.container}>
            <p className={`${styles.text} ${textClass}`}>{props.children}</p>
            <p className={`${styles.overlay} ${textClass}`}>{props.children}</p>
        </div>
    );
}

export default TextOverlay;
