import styles from "./css/HowSteps.module.css";
import circle from "../../assets/Circle.svg";

function HowSteps(props) {
    return (
        <div className={styles.container}>
            <div className={styles.square}>{props.number}</div>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.innerContainer}>
                <div className={styles.iconContainer}>
                    <div className={styles.actionText}>{props.actionText}</div>
                    <img src={circle} className={styles.icon}></img>
                </div>
            </div>
        </div>
    );
}

export default HowSteps;
