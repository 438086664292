import { useState, useEffect } from 'react';
import styles from './css/ProfilePrizes.module.css';
import {AiFillSmile} from 'react-icons/ai'

function ProfilePrizes() {
    const unclaimedPrizes = [
        {"name": "", "image": "", "prizeID": "prize-1"},
        {"name": "", "image": "", "prizeID": "prize-2"},
        {"name": "", "image": "", "prizeID": "prize-3"},
        {"name": "", "image": "", "prizeID": "prize-4"},
        {"name": "", "image": "", "prizeID": "prize-5"},
        {"name": "", "image": "", "prizeID": "prize-6"},
        {"name": "", "image": "", "prizeID": "prize-7"},
        {"name": "", "image": "", "prizeID": "prize-8"},
        {"name": "", "image": "", "prizeID": "prize-9"},
        {"name": "", "image": "", "prizeID": "prize-10"},
        {"name": "", "image": "", "prizeID": "prize-11"},
        {"name": "", "image": "", "prizeID": "prize-12"}
    ]

    const [redeemList, setRedeemList] = useState([]);
    function handleRedeemList(item) {
        const index = redeemList.indexOf(item);
        if (index > -1) {
            setRedeemList(redeemList.filter(id => (id !== item)));
        } else {
            setRedeemList(redeemList => ([...redeemList, item]));
        }
    }

    const prizesList = []
    for (let prize in unclaimedPrizes) {
        let prizeID = unclaimedPrizes[prize]["prizeID"]
        prizesList.push(
            <div className={styles.rewardContainer} id={prizeID} onClick={() => handleRedeemList(prizeID)}>
                <AiFillSmile className={styles.checkMark}/>
            </div>
        )
    }

    useEffect(() => {
        var containers = document.getElementsByClassName(styles.rewardContainer);
        for (var i = 0; i < containers.length; i++) {
            containers[i].classList = `${styles.uncheckedReward} ${styles.rewardContainer}`;         
        }

        redeemList.forEach(id => {
            let rewardContainer = document.getElementById(id);
            rewardContainer.classList = `${styles.checkedReward} ${styles.rewardContainer}`;
        })
    })


    return (
        <div className={styles.container}>
            <p className={styles.title}>MY PRIZES</p>
            <p className={styles.subtitle}>Change identifying information and details about your account.</p>
            <div className={styles.prizeGrid}>
                {prizesList}
            </div>
            <div className={styles.redeemContainer}>
                <div className={styles.redeemBtn}>REDEEM</div>
            </div>
        </div>
    )
}

export default ProfilePrizes
