import styles from './css/LandingPage.module.css';
import HomeHeader from '../components/Home/HomeHeader';
import HomeBody from '../components/Home/HomeBody';
import Footer from '../components/Global/Footer';

function LandingPage() {
    return (
        <div className={styles.homeContainer}>
            <HomeHeader />
            <HomeBody />
            <Footer />
        </div>
    );
}

export default LandingPage;
